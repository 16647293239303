$dark:black; //#231f20;

.footer {
  padding-top: 20px;
  min-height: 100px;
  color: white;
  background-color: $dark;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIcon {
  height: 40px;
  padding: 10px;
}

.smallIcons {
  height: 24px;
}

.contactData {
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 10px;
}

