// $headerHeight: 200px;

$dark:black; //#231f20;

header {
  background-color: $dark;
  // height: $headerHeight;

  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 1;

  // border-bottom: 1px solid #6e6c6c;
}

.logoContainer {
  height: 100px;
  max-width: 300px;
  // display: flex;
  // justify-content: center;
  padding: 14px;
}

.logo {
  max-width: 100%;
  max-height: 100%;

}