.Service_container {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 220px;
  align-items: center;
  margin: 10px;
  position: relative;

  margin: 0 auto;
}

.Service_image {
  height: 160px;
  width: 100%;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
}

.Service_title {
  text-transform: uppercase;
  height: 24px;
  line-height: 24px;
  font-weight: 400;
  font-size: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 120px;
}
