.photoContainer {
  // margin: 10px;
  width: 100%; // calc(100% - 32px);
  height: 300px;
  // background-color: red;
  // margin: 10px;

  // margin: 16px;
  // background-color: #2b2a2a;
}

// .photoContainer:nth-child(odd) {
//   // background-color: #363535;
// }

.serviceLabel {
  color: white;
  text-transform: uppercase;

  // padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
}

.photo {
  max-width: 100%;
  max-height: 100%;
}

#ReactGridGallery {
  // width: 100%;
  height: 180px;
  overflow: hidden;
  max-width: 370px;
  margin: 0 auto;
  // margin-left: auto ;
  // margin-right: auto ;
}